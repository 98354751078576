<template>
    <a-layout-content class="Dashboard">
        <a-breadcrumb style="margin: 16px 24px">
            <a-breadcrumb-item>Trang chủ</a-breadcrumb-item>
            <a-breadcrumb-item>Dashboard</a-breadcrumb-item>
        </a-breadcrumb>
        <div class="Dashboard" :style="{minHeight: 'calc(100vh - 120px)', backgroundColor: '#fff', margin: '15px 25px', padding: '15px' }">
            <h1>Hello World ! Dashboard</h1>
        </div>
    </a-layout-content>
</template>

<script>
    import './_Dashboard.scss'
    export default {
        props: {
        },
    };
</script>
